import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import Cookies from "js-cookie";

interface CheckoutPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const CheckoutPopup: React.FC<CheckoutPopupProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const MERCHANT_STORAGE_KEY = "merchantname";
  const merchantname = Cookies.get(MERCHANT_STORAGE_KEY);

  if (!isOpen) return null;

  const handleCheckoutGuest = () => {
    setModalOpen(false);
    navigate(`/${merchantname}/userdetails`);
  };

  const handleLoginAndCheckout = () => {
    setModalOpen(false);
    navigate(`/${merchantname}/login`);
  };

  return (
    <div className="fixed inset-0 bg-gray-1000 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <h2 className="text-l font-semibold mb-4">Checkout Options </h2>
        <div className="flex flex-col space-y-4">
          <button
            onClick={handleCheckoutGuest}
            className="py-2 px-4 bg-white text-black rounded hover:bg-green-700"
          >
            Checkout as Guest
          </button>
          <button
            onClick={handleLoginAndCheckout}
            className="py-2 px-4 bg-green-500 text-white rounded hover:bg-green-700"
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPopup;
