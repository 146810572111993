import React from "react";
import { useLocation } from "react-router-dom";
import HeaderLogged from "components/Header/HeaderLogged";
import Header from "components/Header/Header";
import Cookies from "js-cookie";

const SiteHeader = () => {
  let location = useLocation();
  const MERCHANT_STORAGE_KEY = "merchantname";
  const merchantname = Cookies.get(MERCHANT_STORAGE_KEY);

  return location.pathname === `/${merchantname}/home2` ? (
    <Header />
  ) : (
    <HeaderLogged />
  );
};

export default SiteHeader;
