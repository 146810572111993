import { NavItemType } from "shared/Navigation/NavigationItem";
import React, { useEffect,useState,ReactElement  } from 'react';
import ncNanoId from "utils/ncNanoId";
import { BsPersonFillAdd } from "react-icons/bs";
import { BiLogIn } from "react-icons/bi";

import { RiHistoryFill } from "react-icons/ri";
import { RiSecurePaymentLine } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { PiSignOutBold } from "react-icons/pi";
import Cookies from 'js-cookie';
const MERCHANT_STORAGE_KEY = "merchantname";
const merchantname = Cookies.get(MERCHANT_STORAGE_KEY);
//  const [userDataString, setUserDataString] = React.useState<string | null>(null);
//   const [SignupData, setSignupData] = React.useState<string | null>(null);
const userData = Cookies.get('userDataaInfo');

const signupData = Cookies.get('SignupData');
  const handleSignOut = () => {
  // Remove the userData from localStorage
  // localStorage.removeItem("userData");
  //   localStorage.removeItem("SignupData");
  //   localStorage.removeItem("CustomerInfo");
  //   localStorage.removeItem("newCard");
  //   localStorage.removeItem("orderCards");
  //   localStorage.removeItem("Info");
  //   localStorage.removeItem("customerAdded");
  //   localStorage.removeItem("addedPayments");
  //   localStorage.removeItem("user");
    //   localStorage.removeItem("paymentId")
    Cookies.remove('CustomerInfo')
    Cookies.remove('userDataaInfo')
    Cookies.remove('addedPayments')
    Cookies.remove('BillingAddress')
    Cookies.remove('checkTime')
    Cookies.remove('fromTime')
    Cookies.remove('toTime')
    Cookies.remove('orderCards')
    Cookies.remove('user')
    Cookies.remove('SignupData')
    Cookies.remove('customerAddeds')
    Cookies.remove('paymentId')
     Cookies.remove('phone')
    sessionStorage.removeItem("onlineOrderSettingsLogo");
      localStorage.clear();
  // Perform any other signout actions, such as redirecting to the login page
};

export const MEGAMENU_TEMPLATES: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home Page",
    children: [
      { id: ncNanoId(), href: "/:merchantname", name: "Home  1" },
      {
        id: ncNanoId(),
        href: "/home2/:merchantname",
        name: "Home  2",
        isNew: true,
      },
      { id: ncNanoId(), href: "/", name: "Header  1" },
      {
        id: ncNanoId(),
        href: "/home2/:merchantname",
        name: "Header  2",
        isNew: true,
      },
      { id: ncNanoId(), href: "/", name: "Coming Soon" },
    ],
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Shop Pages",
    children: [
      {
        id: ncNanoId(),
        href: "/:merchantname/page-collection",
        name: "Category Page 1",
      },
      {
        id: ncNanoId(),
        href: "/:merchantname/page-collection",
        name: "Category Page 2",
      },
      {
        id: ncNanoId(),
        href: "/:merchantname/product-detail/:id",
        name: "Product Page 1",
      },
       {
        id: ncNanoId(),
        href: "/:merchantname/signup",
        name: "Sign Up",
      },
       {
        id: ncNanoId(),
        href: "/:merchantname/login",
        name: "Login",
      },
      {
        id: ncNanoId(),
        href: "/:merchantname/product-detail-2",
        name: "Product Page 2",
      },
      { id: ncNanoId(), href: "/:merchantname/cart", name: "Cart Page" },
      {
        id: ncNanoId(),
        href: "/:merchantname/checkout",
        name: "Checkout Page",
      },
      {
        id: ncNanoId(),
        href: "/:merchantname/userdetails",
        name: "Details Page",
      },
      { id: ncNanoId(), href: "/:merchantname/fullmenu", name: "Full Menu" },
    ],
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Other Pages",
    children: [
      {
        id: ncNanoId(),
        href: "/:merchantname/checkout",
        name: "Checkout Page",
      },
       {
        id: ncNanoId(),
        href: "/:merchantname/signup",
        name: "Sign Up",
      },
       {
        id: ncNanoId(),
        href: "/:merchantname/login",
        name: "Login",
      },
      {
        id: ncNanoId(),
        href: "/:merchantname/userdetails",
        name: "Details Page",
      },
      { id: ncNanoId(), href: "/:merchantname/fullmenu", name: "Full Menu" },
      {
        id: ncNanoId(),
        href: "/:merchantname/page-search",
        name: "Search Page",
      },
      { id: ncNanoId(), href: "/:merchantname/cart", name: "Cart Page" },
      { id: ncNanoId(), href: "/:merchantname/account", name: "Accout Page" },
      {
        id: ncNanoId(),
        href: "/:merchantname/account-my-order",
        name: "Order Page",
      },
      {
        id: ncNanoId(),
        href: "/:merchantname/subscription",
        name: "Subscription",
      },
    ],
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Blog Page",
    children: [
      { id: ncNanoId(), href: "/:merchantname/blog", name: "Blog Page" },
      {
        id: ncNanoId(),
        href: "/:merchantname/blog-single",
        name: "Blog Single",
      },
      { id: ncNanoId(), href: "/:merchantname/about", name: "About Page" },
      { id: ncNanoId(), href: "/:merchantname/contact", name: "Contact Page" },
      { id: ncNanoId(), href: "/:merchantname/login", name: "Login" },
      { id: ncNanoId(), href: "/:merchantname/signup", name: "Signup" },
    ],
  },
];



export const NAVIGATION_DEMO_2: NavItemType[] = [

//   {
//     id: ncNanoId(),
//     href: `/${merchantname}`,
//    name: {userDataa.firstName},
//  },
    
    ...(userData || signupData
      ? [
        //   {
        //     id: ncNanoId(),
        //     href: `/signup/${merchantname}`,
        //   name: "Order History",   
        //  icon: React.createElement(RiHistoryFill) as ReactElement,
        //   },
        //   {
        //     id: ncNanoId(),
        //     href: `/signup/${merchantname}`,
        //     name: "Payment Method",
        //       icon: React.createElement(RiSecurePaymentLine) as ReactElement,
        //   },
        {
      id: ncNanoId(),
      href: `/${merchantname}`,
      name: "Menu",
    },
          {
            id: ncNanoId(),
            href: `/${merchantname}/account`,
            name: "Profile",
             icon: React.createElement(CgProfile) as ReactElement,
          },
          {
            id: ncNanoId(),
            href: `/${merchantname}`,
            name: "Sign Out",
            icon: React.createElement(PiSignOutBold) as ReactElement,
            onClick:handleSignOut
          },
        ]
    : [
     {
      id: ncNanoId(),
      href: `/${merchantname}`,
      name: "Menu",
    },
      
          {
            id: ncNanoId(),
            href: `/${merchantname}/login`,
          name: "Login",
            icon: React.createElement(BiLogIn) as ReactElement,
          },
          {
            id: ncNanoId(),
            href: `/${merchantname}/signup`,
            name: "Sign Up",
            icon: React.createElement(BsPersonFillAdd) as ReactElement,
          },
        ]),
  ];

