import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Checkbox from "shared/Checkbox/Checkbox";
import Input from "shared/Input/Input";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

interface Props {
  isActive: boolean;
  onOpenActive: () => void;
  onCloseActive: () => void;
}

interface IFormInput {
  firstname: string;
  lastname: string;

  phoneNumber: string;
  email: string;
}

const ContactInfo: FC<Props> = ({ isActive, onCloseActive, onOpenActive }) => {
  let navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      phoneNumber: "",
      email: "",
    },
  });
  const MERCHANT_STORAGE_KEY = "merchantname";
  const merchantname = Cookies.get(MERCHANT_STORAGE_KEY);
  const formatPhoneNumber = (phoneNumber: string) => {
    const cleaned = phoneNumber.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (phoneNumber) {
      setValue("phoneNumber", phoneNumber);
    }
    if (match) {
      return `(${match[1]})${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {

    Cookies.set('formDataa',JSON.stringify(data))
    navigate(`/${merchantname}/checkout`, { state: data });
  };

  useEffect(() => {
    const savedFormValue = Cookies.get("formDataa"); // Get the saved cookie value
    if (savedFormValue) {
      try {
        const parsedValue = JSON.parse(savedFormValue); // Parse the saved value
        for (const [key, value] of Object.entries(parsedValue)) {
          setValue(key as any, value); // Set the form values
        }
      } catch (error) {
        console.error("Failed to parse saved form data:", error); // Log if parsing fails
      }
    }
  }, [setValue]);

  const renderAccount = () => {
    return (
      <div className="border dark:border-slate-700 rounded-xl overflow-hidden z-0">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${
              isActive ? "block" : "hidden"
            }`}
          >
            <div className="flex justify-between flex-wrap items-baseline">
              <h3 className="text-lg font-semibold">Contact infomation</h3>
              <span className="text-custom-green text-bold my-1 md:my-0">
                Do not have an account?{` `}
                <a
                  href={`/${merchantname}/signup`}
                  className="text-custom-green-dark font-medium"
                >
                 Sign Up{" "}
                </a>
               
              </span>
            </div>
            <div className="max-w-lg">
              <Label className="text-sm"><span className="text-red-500">*</span>First Name</Label>
              <Controller
                name="firstname"
                control={control}
                rules={{ required: "Field is Required" }}
                render={({ field }) => (
                  <Input {...field} className="mt-1.5" type="text" />
                )}
              />
              {errors.firstname && (
                <span className="text-red-500">{errors.firstname.message}</span>
              )}
            </div>

            <div className="max-w-lg">
              <Label className="text-sm"><span className="text-red-500">*</span>Last Name</Label>
              <Controller
                name="lastname"
                control={control}
                rules={{ required: "Field is Required" }}
                render={({ field }) => (
                  <Input {...field} className="mt-1.5" type="text" />
                )}
              />
              {errors.lastname && (
                <span className="text-red-500">{errors.lastname.message}</span>
              )}
            </div>
            <div className="max-w-lg">
              <Label className="text-sm"><span className="text-red-500">*</span>Your phone number</Label>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: "Field is Required",
                  validate: (value) => {
                    const cleaned = value.replace(/\D/g, "");
                    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                    if (!match) {
                      return "Invalid Format";
                    }
                    return true;
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    className="mt-1.5"
                    onChange={(e) => {
                      const input = e.target.value;
                      const formatted = formatPhoneNumber(input);
                      setValue("phoneNumber", formatted);
                      trigger("phoneNumber");
                    }}
                    type="tel"
                    placeholder="(000)000-0000"
                  />
                )}
              />

              {errors.phoneNumber && (
                <span className="text-red-500">
                  {errors.phoneNumber.message}
                </span>
              )}
            </div>

            <div className="max-w-lg">
              <Label className="text-sm"><span className="text-red-500">*</span>Email Address</Label>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Field is Required",

                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid Email!!",
                  },
                }}
                render={({ field }) => (
                  <Input {...field} className="mt-1.5" type="text" />
                )}
              />
              {errors.email && (
                <span className="text-red-500">{errors.email.message}</span>
              )}
            </div>

            <div className="flex flex-col sm:flex-row pt-6">
              <ButtonPrimary
                className="sm:!px-7 shadow-none"
                // href="/checkout/dev_merchant"
                type="submit"
              >
                Proceed to Payment
              </ButtonPrimary>
              <ButtonSecondary
                className="mt-3 sm:mt-0 sm:ml-3"
                onClick={() => onCloseActive()}
              >
                Cancel
              </ButtonSecondary>
            </div>
          </div>
        </form>
      </div>
    );
  };

  return renderAccount();
};

export default ContactInfo;
