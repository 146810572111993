import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useLocation,
} from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import SiteHeader from "containers/SiteHeader";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountBilling from "containers/AccountPage/AccountBilling";
import CartPage from "containers/ProductDetailPage/CartPage";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
import UserDetails from "containers/ProductDetailPage/UserDetails";
import { Toaster } from "react-hot-toast";
// import FullMenu from "views/FullMenu";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import AccountSavelist from "containers/AccountPage/AccountSavelist";
import AccountOrder from "containers/AccountPage/AccountOrder";
import Cookies from "js-cookie";

const MERCHANT_STORAGE_KEY = "merchantname";

const useMerchantName = () => {
  const { merchantname } = useParams();

  React.useEffect(() => {
    if (merchantname) {
      Cookies.set(MERCHANT_STORAGE_KEY,merchantname)
    }
  }, [merchantname]);

  return merchantname;
};

const ConditionalPageHome = () => {
  const merchantname = useMerchantName();
 
  if (merchantname) {
    return <PageHome />;
  }

  return <Page404 />;
};
const ConditionalProductDetailPage = () => {
  const merchantname = useMerchantName();
 
  if (merchantname) {
    
    return <ProductDetailPage />;
  }

  return <Page404 />;
};
export const pages: Page[] = [
  //{ path: "/:merchantname/home-header-2", component: PageHome },
  //{ path: "/product-detail/:id/:merchantname", component: ProductDetailPage },
  { path: "/:merchantname/account", component: AccountPage },
  { path: "/:merchantname/account-savelists", component: AccountSavelist },
  { path: "/:merchantname/account-my-order", component: AccountOrder },
  { path: "/:merchantname/account-change-password", component: AccountPass },
  { path: "/:merchantname/account-billing", component: AccountBilling },
  { path: "/:merchantname/cart", component: CartPage },
  { path: "/:merchantname/checkout", component: CheckoutPage },
  { path: "/:merchantname/userdetails", component: UserDetails },
  // { path: "/fullmenu/:merchantname", component: FullMenu },
  { path: "/:merchantname/signup", component: PageSignUp },
  { path: "/:merchantname/login", component: PageLogin },
];
const Layout = ({ children }: any) => (
  <div className="flex flex-col min-h-screen">
    <SiteHeader />
    <div className="main-content flex-grow pb-10">{children}</div>
    <Footer  />
  </div>
);

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <Toaster />
      <ScrollToTop />

      <Routes>
        {pages.map(({ component: Component, path }, index) => (
          <Route
            key={index}
            path={path}
            element={
              <Layout>
                <Component />
              </Layout>
            }
          />
        ))}
        <Route
          path="/:merchantname"
          element={
            <Layout>
              <ConditionalPageHome />
            </Layout>
          }
        />
        <Route
          path="/:merchantname/home-header-2"
          element={
            <Layout>
              <PageHome />
            </Layout>
          }
        />
        <Route
          path="/:merchantname/product-detail/:id"
          element={
            <Layout>
              <ConditionalProductDetailPage />
            </Layout>
          }
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MyRoutes;
