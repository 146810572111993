import ApiService from "./ApiService";

export async function getAllCategoryNameData<T>() {
  return ApiService.fetchData<T>({
    url: `/onlineOrder/menu`,
    method: "get",
  });
}
export async function getMenuScheduleData<T>() {
  return ApiService.fetchData<T>({
    url: `/menuSchedule`,
    method: "get",
  });
}
export async function getAllModifiersNameData<T>() {
  return ApiService.fetchData<T>({
    url: `/modifier-set`,
    method: "get",
  });
}
export async function getDataByCategoryId<T>(id: number) {
  return ApiService.fetchData<T>({
    url: `/onlineOrder/menu/${id}`,
    method: "get",
  });
}
export async function getOnlineOrderSettings<T>() {
  return ApiService.fetchData<T>({
    url: `/onlineOrderSettings`,
    method: "get",
  });
}

export async function postDataByCustomerorderDetails<T>(data: any, headers: any) {
 
  return ApiService.fetchData<T>({
    url: `/onlineOrder/payment/customer`,
    method: "post",
    data,
    headers
  });
}
export async function postDataByorderDetails<T>(data: any) {
  return ApiService.fetchData<T>({
    url: `/onlineOrder/payment`,
    method: "post",
    data
  });
}
export async function postLoginData<T>(data: any) {
  return ApiService.fetchData<T>({
    url: `/onlineOrder/customer/login`,
    method: "post",
    data
  });
}
export async function postSignUpData<T>(data: any, cardSave: string) {
  return ApiService.fetchData<T>({
    url: `/onlineOrder/customer`, // Removed cardSave from query params
    method: "post",
    data,
    headers: {
      'cardSave': cardSave, // Adding cardSave to the request headers
    },
  });
}
export async function postPaymentData<T>(data: any, headers: any) {
  return ApiService.fetchData<T>({
    url: `/onlineOrder/customer/payment`,
    method: "post",
    data,
    headers
  });
}