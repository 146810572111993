
import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { Product } from "data/data";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";

import ContactInfo from "./../PageCheckout/ContactInfo";

import { useCartDataProvider } from "contaxt/CartProductContext";
import { CheckIcon, NoSymbolIcon } from "@heroicons/react/24/solid";
import Cookies from "js-cookie";

const UserDetails = () => {
  const { Products, dispatch }: any = useCartDataProvider();
  const [tabActive, setTabActive] = useState<
    "ContactInfo" | "ShippingAddress" | "PaymentMethod"
  >("ContactInfo");
  const navigate = useNavigate();

  const renderStatusSoldout = () => {
    return (
      <div className="rounded-full flex items-center justify-center px-2.5 py-1.5 text-xs text-slate-700 dark:text-slate-300 border border-slate-200 dark:border-slate-700">
        <NoSymbolIcon className="w-3.5 h-3.5" />
        <span className="ml-1 leading-none">Unavailable</span>
      </div>
    );
  };

  const renderStatusInstock = () => {
    return (
      <div className="rounded-full flex items-center justify-center px-2.5 py-1.5 text-xs text-slate-700 dark:text-slate-300 border border-slate-200 dark:border-slate-700">
        <CheckIcon className="w-3.5 h-3.5" />
        <span className="ml-1 leading-none">Available</span>
      </div>
    );
  };

  const handleScrollToEl = (id: string) => {
    const element = document.getElementById(id);
    setTimeout(() => {
      element?.scrollIntoView({ behavior: "smooth" });
    }, 80);
  };

  const calculatingTotalPrice = (item: Product) => {
    const { onlinePrice, quantity, note,modifierChecked, modifierSets } = item;
    let allPrice = onlinePrice;

    if (modifierSets) {
      modifierSets.forEach((modifierSets) => {
        const matchingKey =
          modifierChecked &&
          Object.keys(modifierChecked)?.find(
            (key) => Number(key) === modifierSets.id
          );
        if (matchingKey) {
          modifierSets.modifierSetModifierItems?.forEach((modifierItem) => {
            const modifierItemId = modifierItem.id.toString();
            if (modifierChecked[matchingKey]?.[modifierItemId] !== undefined) {
              allPrice += modifierChecked[matchingKey][modifierItemId]
                ? modifierItem.modifierItem.onlinePrice
                : 0;
            }
          });
        }
      });
    }
    let basePrice = quantity ? allPrice * quantity : allPrice;
    return basePrice;
  };

  const allProductPrice = Products.reduce(
    (accumulator: number, currentValue: Product) =>
      accumulator + calculatingTotalPrice(currentValue),
    0
  ).toFixed(2);
  const amtTax = allProductPrice * (8.25 / 100);
  
  

  const renderLeft = () => {
    return (
      <div className="space-y-8">
        <div id="ContactInfo" className="scroll-mt-24">
          <ContactInfo
            isActive={tabActive === "ContactInfo"}
            onOpenActive={() => {
              setTabActive("ContactInfo");
              handleScrollToEl("ContactInfo");
            }}
            onCloseActive={() => {
              setTabActive("ShippingAddress");
              handleScrollToEl("ShippingAddress");
            }}
          />
        </div>

        {/* <div id="ShippingAddress" className="scroll-mt-24">
          <ShippingAddress
            isActive={tabActive === "ShippingAddress"}
            onOpenActive={() => {
              setTabActive("ShippingAddress");
              handleScrollToEl("ShippingAddress");
            }}
            onCloseActive={() => {
              setTabActive("PaymentMethod");
              handleScrollToEl("PaymentMethod");
            }}
          />
        </div>

        <div id="PaymentMethod" className="scroll-mt-24">
          <PaymentMethod
            isActive={tabActive === "PaymentMethod"}
            onOpenActive={() => {
              setTabActive("PaymentMethod");
              handleScrollToEl("PaymentMethod");
            }}
            onCloseActive={() => setTabActive("PaymentMethod")}
          />
        </div> */}
      </div>
    );
  };
  const MERCHANT_STORAGE_KEY = "merchantname";
  const merchantname = Cookies.get(MERCHANT_STORAGE_KEY);
  
  return (
    <div className="nc-CheckoutPage">
      <Helmet>
        <title>Basil POS Online Ordering</title>
      </Helmet>

      <main className="container py-16 lg:pb-28 lg:pt-20 ">
        <div className="mb-16">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
            User Details
          </h2>
          <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <Link to={`/${merchantname}`} className="">
              Menu
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <Link to={`/${merchantname}/cart`} className="">
              Cart
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            {/* <Link to={"/#"} className="">
              Clothing Categories
            </Link> */}
            {/* <span className="text-xs mx-1 sm:mx-1.5">/</span> */}
            <span className="underline">User Details</span>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <Link to={`/${merchantname}/checkout`} className="">
              Checkout
            </Link>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row">
          <div className="flex-1">{renderLeft()}</div>

          <div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 "></div>
        </div>
      </main>
    </div>
  );
};

export default UserDetails;
