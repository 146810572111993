import React, { useEffect, useState } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
import { Disclosure } from "@headlessui/react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
import { NAVIGATION_DEMO_2 } from "data/navigation";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useCartDataProvider } from "contaxt/CartProductContext";
import { useMediaQuery } from "@react-hook/media-query";
import Cookies from "js-cookie";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
  data = NAVIGATION_DEMO_2,
  onClickClose,
}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 640px)");
 
  const { id } = useParams();
  const [userDataString, setUserDataString] = React.useState<string | null>(
    null
  );
  const [SignupData, setSignupData] = React.useState<string | null>(null);
  
  const userData = Cookies.get("userDataaInfo");
  const signupData = Cookies.get("SignupData");
  const CustomerAdded=Cookies.get("customerAdded");
  const Info=Cookies.get("CustomerInfo");

  const [userDataa, setUserData] = useState<any>(null);
  const [customerAdded, setCustomerAdded] = useState<any>(null);
  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        // Make the API call to fetch data
        if (userData && !signupData && Info) {
          const response = JSON.parse(Info);
          // Assuming the response is an object with a "Customer Info" property
          setUserData(response);
        
        } else if (signupData && CustomerAdded) {
          const response = JSON.parse(CustomerAdded);
         
          setUserData(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call fetchDataFromApi if userData is not available in localStorage
    if (!userDataa) {
      fetchDataFromApi();
    }
  }, [userData, signupData]);
  const handleItemClick = (item: any) => {
    if (item.onClick) {
      item.onClick();
      window.location.href = item.href; // Call the onClick handler if it exists
    } else {
      window.location.href = item.href; // Navigate to the href if onClick doesn't exist
    }
  };
  useEffect(() => {
    setUserDataString(userData?? null);
    setSignupData(signupData?? null);
    setCustomerAdded(CustomerAdded);

    //} Check if either userData or signupData is missing
  }, [userData, signupData]);
  const _renderMenuChild = (
    item: NavItemType,
    itemClass = " pl-3 text-neutral-900 dark:text-neutral-200 font-medium "
  ) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            <NavLink
              to={{
                pathname: i.href || undefined,
              }}
              className={({ isActive }) =>
                `flex text-sm rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 pr-4 ${itemClass} ${
                  isActive ? "text-secondary" : ""
                }`
              }
            >
              <span
                className={`py-2.5 ${!i.children ? "block w-full" : ""}`}
                onClick={onClickClose}
              >
                {i.icon}
                {i.name}
              </span>
              {i.children && (
                <span
                  className="flex items-center flex-grow"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="flex justify-end flex-grow"
                  >
                    <ChevronDownIcon
                      className="ml-2 h-4 w-4 text-slate-500"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
              )}
            </NavLink>
            {i.children && (
              <Disclosure.Panel>
                {_renderMenuChild(
                  i,
                  "pl-3 text-slate-600 dark:text-slate-400 "
                )}
              </Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };
  const { setSearchProduct, searchProduct }: any = useCartDataProvider();
  const location = useLocation();

  const MERCHANT_STORAGE_KEY = "merchantname";
  const merchantname = Cookies.get(MERCHANT_STORAGE_KEY);
  const _renderItem = (item: NavItemType, index: number) => {
    
    if (item.name === "Order History" && !isMobile) {
      return null;
    }
    return (
      <Disclosure
        key={index}
        as="li"
        className="text-slate-900 dark:text-white"
      >
        <NavLink
          className={({ isActive }) =>
            `flex w-full items-center py-2.5 px-5 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg ${
              isActive ? "text-secondary" : ""
            }`
          }
          to={{
            pathname: item.href || undefined,
          }}
        >
          <span
            className={!item.children ? "block w-full" : ""}
            onClick={() => handleItemClick(item)}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            {item.icon && (
              <span style={{ color: " #7BB542" }} className="text-lg mr-2">
                {item.icon}
              </span>
            )}{" "}
            {/* Render icon with custom styling */}
            <span>{item.name}</span> {/* Render item name */}
          </span>
          {item.children && (
            <span
              className="block flex-grow"
              onClick={(e) => e.preventDefault()}
            >
              <Disclosure.Button
                as="span"
                className="flex justify-end flex-grow"
              >
                <ChevronDownIcon
                  className="ml-2 h-4 w-4 text-neutral-500"
                  aria-hidden="true"
                />
              </Disclosure.Button>
            </span>
          )}
        </NavLink>
        {item.children && (
          <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
        )}
      </Disclosure>
    );
  };

  

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setSearchProduct(e.target.value);
      onClickClose?.();
      navigate(`/${merchantname}`);
    }
  };
  return (
    <div className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      <div className="py-6 px-5">
        <Logo className="flex-shrink-0" />
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>
      
      </div>
      {userData || signupData  ? (
        <div className="px-5 py-2">
          <span className="text-lg font-semibold text-gray-800 dark:text-gray-200">
            {/* Greeting with the user's name */}
           
            {`Hello, 
             ${userDataa?.firstName} ${userDataa?.lastName}
            `}
          </span>
        </div>
      ) : (
        ""
      )}
      <ul className="flex flex-col py-6 px-2 space-y-1">
        {data.map(_renderItem)}
      </ul>
     
    </div>
  );
};

export default NavMobile;
