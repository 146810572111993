import axios, { AxiosRequestConfig } from "axios";
import appConfig from "configs/app.config";
import deepParseJson from "utils/deepParseJson";
 import { useParams } from 'react-router-dom';
import Cookies from "js-cookie";
const unauthorizedCode = [401];
const PERSIST_STORE_NAME = "admin";
const MERCHANT_STORAGE_KEY = "merchantname";
// const authToken =

//   "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxLENoYWRuaSxyb3ksTW9vcmUsMTIzMTIzMTIzNCx0ZXN0QHBvc2Jhc2lsLmNvbSxCQVNJTDAwNzY3MyIsImlzcyI6IkJhc2lsLVBvcyIsImlhdCI6MTcxNDU2OTgxOCwiZXhwIjoxNzE0NjU2MjE4fQ.gpyzL-KEnyMtN7bJixrGC2vnIFgTbekJWUNBr_6pMmWvw7kcYx9aVa428_hpsyydpSVpCXcSylR9UJk6su_j-w";

const BaseService = axios.create({
  timeout: 60000,
  baseURL: appConfig.apiPrefix,
});
// const saveMerchantToLocalStorage = (merchant: any) => {
//   localStorage.setItem(MERCHANT_STORAGE_KEY, merchant);
// };

// const extractMerchantFromURL = () => {
//   const pathArray = window.location.pathname.split("/");
//   return pathArray[1] || null;
// };

export const getHeaders = (): AxiosRequestConfig["headers"] => {
  const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
  const persistData = deepParseJson(rawPersistData);
   
  const timeZone = "America/Chicago";
  // const merchantname = localStorage.getItem(MERCHANT_STORAGE_KEY);
  const merchantname = Cookies.get(MERCHANT_STORAGE_KEY);
 
  const headers: AxiosRequestConfig["headers"] = {
    // MID: merchant,
    MID: merchantname,
    "Content-Type": "application/json",
    TimeZone: timeZone,
    Accept: "*/*",
    "Cache-Control": "no-cache",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
    // Authorization: `Bearer ${authToken}`, // Add the Authorization header with the token
  };

  // Check if authToken is defined before adding Authorization header
  // if (authToken) {
  //   headers.Authorization = `Bearer ${authToken}`;
  // }

  return headers;
};

BaseService.interceptors.request.use(
  (config: any) => {
    const headers: AxiosRequestConfig["headers"] = getHeaders();
    // Assign the headers to the config object
    config.headers = headers;
    //const { merchantname } = useParams();
   
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default BaseService;
