import React, {
  createContext,
  useContext,
  useReducer,
  useState,
  Dispatch,
} from "react";
import CartReducer from "./CartReducer";
import { Product } from "data/data"; // Ensure this path is correct
import Cookies from "js-cookie";

type ReducerAction = {
  type: string;
  payload: any;
};

interface InitialState {
  Products: [];
  state: Product[];
  dispatch: Dispatch<ReducerAction>;
  total_price: any;
}

const Cartcontext = createContext<InitialState>({
  Products: [],
  dispatch: () => undefined,
  state: [],
  total_price: "",
});

const initialValue = () => {
  try {
    const initialState = Cookies.get("orderCards");
    if (initialState === null) {
      return {
        Products: [],
        total_price: "",
      };
    }
    if (typeof initialState === 'string') {
      return JSON.parse(initialState);
    } else {
      // Handle the case where initialState is not a string
      // For example, you could return a default value or throw an error
      return {
        Products: [],
        total_price: "",
      };
    }
  } catch (error) {
    return {
      Products: [],
      total_price: "",
    };
  }
};

export const ProductCartContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(CartReducer, initialValue());
  const [searchProduct, setSearchProduct] = useState<String>("");
  Cookies.set("orderCards", JSON.stringify(state));

  const value = {
    ...state,
    dispatch,
    searchProduct,
    setSearchProduct,
  };

  return <Cartcontext.Provider value={value}>{children}</Cartcontext.Provider>;
};

export const useCartDataProvider = () => {
  return useContext(Cartcontext);
};
