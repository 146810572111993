import { Popover, Transition } from "@headlessui/react";
import Prices from "components/Prices";
import { useCartDataProvider } from "contaxt/CartProductContext";
import { CategoryData, Product } from "data/data";
import { FC, Fragment } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { MdOutlineModeEdit } from "react-icons/md";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useState } from 'react';
import NcImage from "shared/NcImage/NcImage";
import Cookies from "js-cookie";
export interface CartDropdownProps {
  categoryData?: CategoryData[];
}

const CartDropdown: FC<CartDropdownProps> = ({ categoryData }) => {
  const { Products, dispatch } = useCartDataProvider() as {
    Products: Product[];
    dispatch: any;
  };
  const MERCHANT_STORAGE_KEY = "merchantname";
  const merchantname = Cookies.get(MERCHANT_STORAGE_KEY);
  const navigate = useNavigate();
  const { id } = useParams();
const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const calculatingTotalPrice = (item: Product) => {
   
    const { onlinePrice, quantity, modifierChecked, modifierSets, note } = item;
    
    let allPrice = onlinePrice;

    if (modifierSets) {
      modifierSets.forEach((modifierSets) => {
        const matchingKey =
          modifierChecked &&
          Object.keys(modifierChecked)?.find(
            (key) => Number(key) === modifierSets.id
          );
        if (matchingKey) {
          modifierSets.modifierSetModifierItems?.forEach((modifierItem) => {
            const modifierItemId = modifierItem.id.toString();
            if (modifierChecked[matchingKey]?.[modifierItemId] !== undefined) {
              allPrice += modifierChecked[matchingKey][modifierItemId]
                ? modifierItem.modifierItem.onlinePrice
                : 0;
            }
          });
        }
      });
    }
    let basePrice = quantity ? allPrice * quantity : allPrice;
    return basePrice;
  };
  const allProductPrice = Products.reduce(
    (accumulator: number, currentValue: Product) =>
      accumulator + calculatingTotalPrice(currentValue),
    0
  ).toFixed(2);
  const renderProduct = (close: () => void) => {
    return (
      <>
        {Products?.length === 0 ? (
          <h2
            className="text-lg cursor-pointer text-center mt-3 "
            onClick={() => close()}
          >
            <div className="mb-12 sm:mb-6">
              <h2 className="mb-4 block text-lg sm:text-lg lg:text-lg font-semibold ">
                Your cart is currently empty
              </h2>
              <span className="text-base">
                Browse our menu to start adding items to your cart
              </span>
              <div className="flex justify-center">
                {/* <ButtonPrimary href={`/${merchantname}`} className="mt-8 ">
                  Browse Menu
                </ButtonPrimary> */}
              </div>
            </div>
          </h2>
        ) : (
          <>
            {Products?.map((item: Product, index: any) => (
              <div key={index} className="flex py-5 last:pb-0">
                <div className="relative cursor-pointer h-20 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
                  <NcImage
                    src={item.imageUrl}
                    containerClassName="flex aspect-w-1 aspect-h-1 w-full h-0"
                    className="w-full rounded-md object-cover"
                  />
                  <Link
                    onClick={close}
                    className="absolute inset-0"
                    to={`/${merchantname}/product-detail/${item.id}`}
                  />
                </div>

                <div className="ml-4 flex flex-1 flex-col">
                  <div>
                    <div className="flex justify-between ">
                      <div>
                        <h3 className="text-base cursor-pointer font-medium ">
                          <Link
                            onClick={close}
                            to={`/${merchantname}/product-detail/${item.id}`}
                          >
                            {item.name}
                          </Link>
                        </h3>
                        <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                          <span>
                            {categoryData && (
                              <>
                                {
                                  categoryData?.find((category) =>
                                    category.items.some(
                                      (items) => items.id === item.id
                                    )
                                  )?.name
                                }
                              </>
                            )}
                          </span>
                          {/* <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                          <span>
                            {item.isAvailable ? "Available" : "Unavailable"}
                          </span> */}
                        </p>
                      </div>
                      <Prices
                        onlinePrice={calculatingTotalPrice(item)}
                        unit={item.unit?.name}
                        priceType={item.priceType?.name}
                      />
                    </div>
                  </div>
                  <div className="flex flex-1 items-end justify-between text-sm">
                    <p className="text-gray-500 dark:text-slate-400">
                      {" "}
                      Qty {item.quantity}
                    </p>
                    <div className="flex">
                      <div
                        onClick={() =>
                          navigate(
                            `/${merchantname}/product-detail/${item.id}`,
                            {
                              state: item.modifierChecked,
                            }
                          )
                        }
                        className="mr-3 cursor-pointer"
                      >
                        {/* <MdOutlineModeEdit style={{ color: '#7BB542',fontSize: '1.3rem' }} /> */}
                      </div>
                      <div
                        onClick={() =>
                          dispatch({
                            type: "REMOVE_ITEMS",
                            payload: item,
                          })
                        }
                        className="cursor-pointer"
                      >
                        {/* <MdOutlineDeleteOutline style={{ color: '#7BB542',fontSize: '1.3rem' }} /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="bg-neutral-50 dark:bg-slate-900 p-5">
              <p className="flex justify-between font-semibold text-slate-900 dark:text-slate-100">
                <span>
                  <span>Subtotal</span>
                  <span className="block text-sm text-slate-500 dark:text-slate-400 font-normal">
                    Taxes are calculated at checkout.
                  </span>
                </span>
                <span className="">${allProductPrice}</span>
              </p>
              {/* <div className="flex space-x-2 mt-5">
                <ButtonSecondary
                  href={`/cart/${merchantname}`}
                  className="flex-1 border border-slate-200 dark:border-slate-700"
                  onClick={close}
                >
                  View cart
                </ButtonSecondary>
                
              </div> */}
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <Popover
      className="relative"
      onMouseEnter={() => setIsPopoverOpen(true)}
      onMouseLeave={() => setIsPopoverOpen(false)}
    >
      {({ open, close }) => (
        <>
          <Link
            to={`/${merchantname}/cart`}
            className="relative"
            onClick={() =>
              !window.matchMedia("(max-width: 640px)").matches &&
              setIsPopoverOpen(!isPopoverOpen)
            }
          >
            <div
              className={`
                   ${isPopoverOpen ? "" : "text-opacity-90"}
                 group w-10 h-10 sm:w-12 sm:h-12 hover:bg-slate-100 dark:hover:bg-slate-800 rounded-full inline-flex items-center justify-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative `}
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            >
              <div className="w-3.5 h-3.5 flex items-center justify-center bg-custom-green absolute top-1.5 right-1.5 rounded-full text-[10px] leading-none text-white font-medium">
                <span className="custom-green ">{Products?.length}</span>
              </div>
              <svg
                className="w-6 h-6"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L4.75 13.96C4.61 15.59 5.89999 16.99 7.53999 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82001"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 8H21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </Link>
          {isPopoverOpen &&
            !window.matchMedia("(max-width: 640px)").matches && (
              <div className="absolute z-10 w-screen max-w-xs sm:max-w-md px-4 mt-3.5 right-0 ">
                {/* Popover content */}
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10">
                  <div className="relative bg-white dark:bg-neutral-800">
                    <div className="max-h-[60vh] p-5 overflow-y-auto hiddenScrollbar">
                      <h3 className="text-xl font-semibold">Your Order</h3>
                      <div className="divide-y divide-slate-100 dark:divide-slate-700">
                        {renderProduct(() => setIsPopoverOpen(false))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </>
      )}
    </Popover>
  );
};

export default CartDropdown;
