import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { CategoryData, PRODUCTS, Product } from "data/data";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { apiGetItemData } from "services/MenuService";
import { Disclosure, Transition } from "@headlessui/react";
import Checkbox from "shared/Checkbox/Checkbox";
import NcImage from "shared/NcImage/NcImage";
import { useCartDataProvider }  from "contaxt/CartProductContext";
import Cookies from "js-cookie";

export interface ProductQuickViewProps {
  className?: string;
  productId?: number;
  categoryData?: CategoryData[];
}

const ProductQuickView: FC<ProductQuickViewProps> = ({
  className = "",
  productId,
  categoryData,
}) => {
  const { status } = PRODUCTS[0];
  const [qualitySelected, setQualitySelected] = React.useState(1);
  const [itemData, setItemData] = useState<Product[]>([]);
  const [checkedItem, setCheckedItem] = useState<Record<number, boolean>>({});
  const [modifierCheckedDatastate, setModifierCheckedDatastate] = useState<any>(
    []
  );
  const navigate = useNavigate();
  const { dispatch } = useCartDataProvider();
  const MERCHANT_STORAGE_KEY = "merchantname";
  const merchantname = Cookies.get(MERCHANT_STORAGE_KEY);

  const handleCheckboxChange = (
    checked: boolean,
    id: number,
    item: any,
    nameid: number
  ) => {
    setCheckedItem((prevCheckedItem) => {
      return {
        ...prevCheckedItem,
        [id]: checked,
      };
    });
    setModifierCheckedDatastate((prev: any) => ({
      ...prev,
      [nameid]: {
        ...(prev[nameid] || {}),
        [id]: checked,
      },
    }));
  };
  // const getItemData = async () => {
  //   try {
  //     const res = await apiGetItemData();
  //     const data = res.data as any;
  //     // console.log(data.map(items=>items.items),"datanewapi")
  //      const orderFee = data.onlineOrderSettings.onlineOrderFee;
  // localStorage.setItem('orderFee', orderFee);
  //     const items = data.items.map((items: any) => items.items);
  //     const flattenedItems = items.flatMap((innerArray: any) => innerArray);
  //     console.log(flattenedItems, "item");

  //     setItemData(flattenedItems as Product[]);
  //   } catch (error) {
  //     console.log("Error", error);
  //   }
  // };
  // useEffect(() => {
  //   getItemData();
  // }, [productId]);

  useEffect(() => {
    const modifierCheckedData = itemData.map((ele: any) => {
      return { ...ele, modifierChecked: modifierCheckedDatastate };
    });
    setItemData(modifierCheckedData);
  }, [modifierCheckedDatastate]);

  const getProductCategoryNames = () => {
    if (!itemData) {
      return [];
    }
    const productId = itemData.map((item) => item.id);
    const productCategory = categoryData?.find((category: any) =>
      category.items.some((item: any) => item.id === productId[0])
    );
    const cateogryNames = productCategory ? productCategory.name : [];
    return cateogryNames;
  };
  const productCategoryNames = getProductCategoryNames();

  const notifyAddTocart = () => {
    toast.custom(
      (t) => (
        <Transition
          appear
          show={t.visible}
          className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
          enter="transition-all duration-150"
          enterFrom="opacity-0 translate-x-20"
          enterTo="opacity-100 translate-x-0"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-20"
        >
          <p className="block text-base font-semibold leading-none">
            Added to cart!
          </p>
          <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
          {renderProductCartOnNotify()}
        </Transition>
      ),
      { position: "top-right", id: "nc-product-notify", duration: 1000 }
    );
  };

  const renderProductCartOnNotify = () => {
    return (
      <>
        {itemData.map((item, index) => (
          <div key={index} className="flex ">
            <div className="h-24 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
              <NcImage
                containerClassName="flex aspect-w-11 aspect-h-13 w-full h-0"
                src={item.imageUrl}
                className="h-full w-full object-cover object-center"
              />
            </div>

            <div className="ml-4 flex flex-1 flex-col">
              <div>
                <div className="flex justify-between ">
                  <div>
                    <h3 className="text-base font-medium ">{item.name}</h3>
                    <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                      <span>{productCategoryNames}</span>
                      {/* <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                      <span>
                        {item.isAvailable ? "Available" : "Unavailable"}
                      </span> */}
                    </p>
                  </div>
                  <Prices
                    onlinePrice={item.onlinePrice}
                    unit={item.unit?.name}
                    priceType={item.priceType?.name}
                    className="mt-0.5"
                  />
                </div>
              </div>
              <div className="flex flex-1 items-end justify-between text-sm">
                <p className="text-gray-500 dark:text-slate-400">
                  Qty {item.quantity}
                </p>

                <div className="flex">
                  {/* <Link
                    to={"/cart/dev_merchant"}
                    className="font-medium text-primary-6000 dark:text-primary-500 "
                  >
                    View cart
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  const renderStatus = () => {
    if (!status) {
      return null;
    }
    const CLASSES =
      "absolute top-3 left-3 px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-slate-700 text-slate-900 dark:text-slate-300";
    if (status === "New in") {
      return (
        <div className={CLASSES}>
          <SparklesIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "50% Discount") {
      return (
        <div className={CLASSES}>
          <IconDiscount className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "Sold Out") {
      return (
        <div className={CLASSES}>
          <NoSymbolIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "limited edition") {
      return (
        <div className={CLASSES}>
          <ClockIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    return null;
  };

  const descriptionContent = () => {
    return (
      <>
        {itemData.map((item) => (
          <div key={item.id} className="w-full rounded-2xl space-y-2.5">
            <h3 className="flex items-center justify-between w-full px-4 py-2 font-medium text-left bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 ">
              Description
            </h3>
            <p>{item.description}</p>
          </div>
        ))}
      </>
    );
  };

  const modifierSetsContent = () => {
    return (
      <>
        {itemData.map((item, index) => (
          <div key={index} className="w-full rounded-2xl space-y-2.5">
            {item?.modifierSets?.map((modifier, index) => (
              <Disclosure key={index}>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2 font-medium text-left bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 ">
                      <span>{modifier.name}</span>
                      {!open ? (
                        <PlusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                      ) : (
                        <MinusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                      )}
                    </Disclosure.Button>
                    {modifier?.modifierSetModifierItems?.map(
                      (modifierCheck, index) => (
                        <Disclosure.Panel
                          key={index}
                          className="p-4 pt-3 last:pb-0 text-slate-600 text-sm dark:text-slate-300 leading-6"
                          as="div"
                        >
                          <Checkbox
                            key={modifierCheck.id}
                            sizeClassName="w-5 h-5"
                            labelClassName="text-sm font-normal"
                            label={modifierCheck.modifierItem?.name}
                            name={modifierCheck.id}
                            onChange={(e) =>
                              handleCheckboxChange(
                                e,
                                modifierCheck?.id as number,
                                modifierCheck.modifierItem?.name as string,
                                modifier.id
                              )
                            }
                            defaultChecked={
                              checkedItem[modifierCheck?.id as number]
                                ? true
                                : false
                            }
                          />
                        </Disclosure.Panel>
                      )
                    )}
                  </>
                )}
              </Disclosure>
            ))}
          </div>
        ))}
      </>
    );
  };

  const renderSectionContent = () => {
    return (
      <div className="space-y-8">
        {itemData.map((item, index) => (
          <div key={index}>
            <h2
              key={item.id}
              onClick={() =>
                navigate(`/${merchantname}/product-detail/${item.id}`, {
                  state: itemData,
                })
              }
              className="text-2xl cursor-pointer font-semibold hover:text-primary-6000 transition-colors"
            >
              {item.name}
            </h2>
            <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
              <Prices
                contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold"
                onlinePrice={
                  itemData[0].quantity
                    ? item.onlinePrice * itemData[0].quantity
                    : item.onlinePrice
                }
                unit={item.unit?.name}
                priceType={item.priceType?.name}
              />
              <div className="h-6 border-l border-slate-300 dark:border-slate-700"></div>
              <div className="flex items-center">
                <Link
                  to={`/${merchantname}/product-detail/${item.id}`}
                  className="flex items-center text-sm font-medium"
                >
                  {/* {item.isAvailable ? (
                    <span>Available</span>
                  ) : (
                    <span>Unavailable</span>
                  )} */}
                </Link>
              </div>
            </div>
          </div>
        ))}
        <div className="flex space-x-3.5">
          <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
            {itemData[0] && (
              <NcInputNumber
                defaultValue={qualitySelected}
                onChange={setQualitySelected}
                id={productId}
                quantity={itemData[0] ? itemData[0].quantity : 1}
                setItemData={setItemData}
                itemData={itemData[0] ? itemData : []}
              />
            )}
          </div>
          <ButtonPrimary
            className="flex-1 flex-shrink-0"
            onClick={() => {
              notifyAddTocart();
              dispatch({ type: "ADD_TO_CART", payload: itemData[0] });
            }}
          >
            <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
            <span className="ml-3">Add to cart</span>
          </ButtonPrimary>
        </div>
        <hr className=" border-slate-200 dark:border-slate-700"></hr>
        {/* ---------- Description ----------  */}
        {descriptionContent()}
        {/* ---------- ModifierContent ----------  */}
        {modifierSetsContent()}
      </div>
    );
  };

  return (
    <div className={`nc-ProductQuickView ${className}`}>
      <div className="lg:flex">
        {itemData.map((item, index) => (
          <div key={index} className="w-full lg:w-[50%] ">
            <div className="relative">
              <div className="aspect-w-16 aspect-h-16">
                <NcImage
                  src={item.imageUrl}
                  containerClassName="flex aspect-w-11 aspect-h-11 w-full h-0"
                  className="w-full rounded-xl object-cover"
                  alt="product detail 1"
                />
              </div>
              {renderStatus()}
              <LikeButton className="absolute right-3 top-3 " />
            </div>
          </div>
        ))}
        {/* SIDEBAR */}
        <div className="w-full lg:w-[50%] pt-6 lg:pt-0 lg:pl-7 xl:pl-8">
          {renderSectionContent()}
        </div>
      </div>
    </div>
  );
};

export default ProductQuickView;
