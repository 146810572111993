
// import MyRouter from "routers/index";
import { ProductCartContextProvider } from "./contaxt/CartProductContext"
import { Helmet, HelmetProvider } from "react-helmet-async";
import MyRoutes from "routers/index";

function App() {
  return (
    <HelmetProvider>
    <Helmet>
      <meta charSet="UTF-8" />
      <link rel="icon" type="image/svg+xml" href="src/images/Basil_icon.png" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="theme-color" content="#000000" />
      <meta name="description" content="Basil POS Online Ordering" />
      <title>Basil POS Online Ordering</title>
    </Helmet>
    <ProductCartContextProvider>
      {/* MAIN APP */}
      <div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
        <MyRoutes />
      </div>
    </ProductCartContextProvider>
  </HelmetProvider>
  );
}

export default App;
