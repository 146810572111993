import { Popover, Transition } from "@headlessui/react";

import { Fragment } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Button from "shared/Button/Button";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SwitchDarkMode2 from "shared/SwitchDarkMode/SwitchDarkMode2";
import { BsPersonFillAdd } from "react-icons/bs";
import { BiLogIn } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import { RiHistoryFill } from "react-icons/ri";
import { RiSecurePaymentLine } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { PiSignOutBold } from "react-icons/pi";
import ApiService from "services/ApiService";
import Cookies from "js-cookie";
export default function AvatarDropdown() {
  const MERCHANT_STORAGE_KEY = "merchantname";
  const merchantname = Cookies.get(MERCHANT_STORAGE_KEY);
  const userDataString = Cookies.get("userDataaInfo");
  const customerInfo=Cookies.get("CustomerInfo");
  const SignupData = Cookies.get("SignupData");
  const CustomerAdded=Cookies.get("customerAdded");
  
  const [open, setOpen] = useState(false); // State to manage popover open/close

  const handleClosePopover = () => {
    
    setOpen(false);
  };
  
  const [userData, setUserData] = useState<any>(null);
  const [customerAdded, setCustomerAdded] = useState<any>(null);
  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        // Make the API call to fetch data
        if (userDataString && customerInfo && !SignupData) {
          const response = JSON.parse(customerInfo);
          // Assuming the response is an object with a "Customer Info" property
          setUserData(response);
         
        } else if (SignupData && CustomerAdded) {
          const response = JSON.parse(SignupData);
          const responsetwo = JSON.parse(CustomerAdded);
         
          setUserData(responsetwo);
          setCustomerAdded(responsetwo);
        }
        else{
          setUserData(null);
          setCustomerAdded(null);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call fetchDataFromApi if userData is not available in localStorage
    if (!userData) {
      fetchDataFromApi();
    }
  }, [userDataString, SignupData]);
  const checkSessionTimeout = () => {
   
    const loginTime = Cookies.get("loginTime");
    if (!loginTime) return;

    const currentTime = new Date().getTime();
    const timeElapsed = currentTime - parseInt(loginTime, 10);
    const twentyFourHours = 24* 60* 60 * 1000;

    if (timeElapsed > twentyFourHours) {
      handleSignOut();
    }
  };
  
  const handleSignOut = () => {
    // localStorage.removeItem("userData");
    // localStorage.removeItem("SignupData");
    // localStorage.removeItem("CustomerInfo");
    // localStorage.removeItem("newCard");
    // localStorage.removeItem("orderCards");
    // localStorage.removeItem("Info");
    // localStorage.removeItem("customerAdded");
    // localStorage.removeItem("addedPayments");
    // localStorage.removeItem("user");
      // localStorage.clear();
      Cookies.remove("CustomerInfo");
      Cookies.remove("userDataaInfo");
      Cookies.remove('addedPayments')
      Cookies.remove('BillingAddress')
      Cookies.remove('checkTime')
      Cookies.remove('fromTime')
      Cookies.remove('toTime')
      Cookies.remove('orderCards')
      Cookies.remove('user')
      Cookies.remove('SignupData')
      Cookies.remove('customerAdded')
      Cookies.remove('loginTime')
      Cookies.remove('orderFee')
      Cookies.remove('paymentId')
      Cookies.remove('orderFeeLabel')
      Cookies.remove('phone')
      sessionStorage.removeItem("onlineOrderSettingsLogo"); 
    window.location.href = `/${merchantname}`;
    window.location.reload();
  };
  useEffect(() => {
    
    const interval = setInterval(() => {
      checkSessionTimeout();
    }, 60000 * 60 * 24);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="AvatarDropdown ">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              onClick={() => {
                setOpen(!open);
                handleClosePopover();
              }}
              className={`w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center`}
            >
              <svg
                className=" w-6 h-6"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Popover.Button>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-3.5 -right-10 sm:right-0 sm:px-0">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  {userData === null ? (
                    <div className="relative grid grid-cols-1 gap-1 bg-white dark:bg-neutral-800 py-7 px-6">
                      <div className="flex items-center space-x-3">
                        {/* <Avatar imgUrl={avatarImgs[7]} sizeClass="w-12 h-12" /> */}
                        <div className="flex-grow">
                          <h4 className="font-semibold">My Account</h4>
                          <p className="text-xs mt-0.5">Description</p>
                        </div>
                      </div>
                      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300"></div>
                      <div className="ml-2 flex items-center">
                        <a
                          href={`/${merchantname}/login`}
                          onClick={close}
                          className="text-sm font-sm btn btn-secondary"
                        >
                          <span className="inline-block mr-1 text-lg">
                            <BiLogIn style={{ color: " #7BB542" }} />
                          </span>
                          Log In
                        </a>
                      </div>
                      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300"></div>
                      <div className="ml-2 flex items-center">
                        <a
                          href={`/${merchantname}/signup`}
                          onClick={close}
                          className="text-sm font-sm btn btn-secondary"
                        >
                          <span className="inline-block mr-1 text-lg">
                            <BsPersonFillAdd style={{ color: " #7BB542" }} />
                          </span>{" "}
                          Sign Up
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className="relative grid grid-cols-1 gap-1 bg-white dark:bg-neutral-800 py-4 px-4">
                      <div
                        className="flex items-center space-x-3"
                        style={{ borderBottom: "2px solid #7BB542" }}
                      >
                        {/* <Avatar imgUrl={avatarImgs[7]} sizeClass="w-12 h-12" /> */}
                        <div className="flex-grow">
                          <h4 className="font-bold">
                            {userData?.firstName} {userData?.lastName}
                          </h4>
                          {/* <p className="text-xs mt-0.5">Description</p> */}
                        </div>
                      </div>
                      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300"></div>
                      {/* <div className="ml-2 flex items-center">
                        <Link
                          to={`/login/${merchantname}`}
                         onClick={close}
                          className="text-sm font-sm btn btn-secondary"
                        >
                          <span className="inline-block mr-1 text-lg">
                         <RiHistoryFill style={{ color: ' #7BB542' }} />
                          </span>
                          Order History
                        </Link>
                      </div>
                      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300"></div>
                      <div className="ml-2 flex items-center">
                        <Link
                          to={`/signup/${merchantname}`}
                          onClick={close}
                          className="text-sm font-sm btn btn-secondary"
                        >
                          <span className="inline-block mr-1 text-lg">
                          <RiSecurePaymentLine style={{ color: ' #7BB542' }}/>
                          </span>{" "}
                         Payment Method
                        </Link>
                      </div> */}
                      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300"></div>
                      <div className="ml-2 flex items-center">
                        <a
                          href={`/${merchantname}/account`}
                          onClick={close}
                          className="text-sm font-sm btn btn-secondary"
                        >
                          <span className="inline-block mr-1 text-lg">
                            <CgProfile style={{ color: " #7BB542" }} />
                          </span>{" "}
                          Profile
                        </a>
                      </div>
                      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300"></div>
                      <div className="ml-2 flex items-center">
                        <a
                          onClick={handleSignOut}
                          href={`/${merchantname}`}
                          className="text-sm font-sm btn btn-secondary"
                        >
                          <span className="inline-block mr-1 text-lg">
                            <PiSignOutBold style={{ color: " #7BB542" }} />
                          </span>{" "}
                          Sign Out
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
